import domReady from '@roots/sage/client/dom-ready';
import { initializeBlock } from '@scripts/initializeBlock';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

/**
 * Initialize Swiper
 * @param {HTMLElement} block - The block element containing the swiper container.
 */
const initializeSwiper = (block) => {
  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper(block.querySelector('.swiper'), {
    slidesPerView: 1,
    slidesPerGroup: 1,
    speed: 600,
    pagination: {
      el: block.querySelector('.swiper-pagination'),
      clickable: true,
    },
    navigation: {
      nextEl: block.querySelector('.swiper-button-next'),
      prevEl: block.querySelector('.swiper-button-prev'),
    },
  });
};

/**
 * Dom ready
 */
domReady(() => {
  initializeBlock('.wp-block-testimonials', initializeSwiper);
});
